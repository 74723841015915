body {
  font-family: roboto, sans-serif;
  -webkit-overflow-scrolling: touch;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

ul,
li {
  list-style-type: none;
}
