.account-settings form {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 80%;
  margin: 1rem auto;
}
.btnContainer {
  display: flex;
  justify-content: flex-end;
}
.loader-wrapper {
  width: 80%;
  margin: 1rem auto;
}
